import React, { Component } from "react"
import Layout from "../components/layout"
import bg from "../images/backgrounds/detail-bg.png"
import Row from "../components/row"
import Form from "../components/form"
import SEO from "../components/seo"
import text from "../data/nl"
import crown from "../images/contact/Aeroparc-vlaggen-lichtreclame.jpg"
import { Helmet } from "react-helmet"

class Contact extends Component {
  componentDidMount () {
    window.gtag('event', 'conversion', {'send_to': 'AW-709920126/K6JTCO_Xwd8BEP6KwtIC'});
  }
  render() {
    return (
    <Layout bigfoot={false} hideMap={true}>
      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content="block-all-mixed-content"/>
      </Helmet>
      <SEO title={text.contact.meta.title} description={text.contact.meta.description}/>
      <Row size="small" image={bg} classes="pt-200" id="Contact">
        <Form/>
        <div className="column col-12 mt-50">
          <iframe title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d316990.1067991603!2d4.953807860808083!3d51.63263860541712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6a2a8740abfa9%3A0xaefbac89fdb84f32!2sEricssonstraat+2%2C+De+Haansberg%2C+5121+ML+Rijen!5e0!3m2!1snl!2snl!4v1423754194729"
                  width="100%" height="500"/>
          <p className="align-center mt-30 mb-30" dangerouslySetInnerHTML={{ __html: text.contact.route }}/>

          <img src={crown} alt="Crown Business Center" className="full-width"/>
        </div>
      </Row>
    </Layout>
    )
  }
}

export default Contact;
