import text from "../data/nl"
import React, { Component } from "react"
import axios from "axios"
import { contact } from "../config/url.conf"
import BarLoader from "react-spinners/BarLoader"

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      emailAddress: "",
      subject: "",
      message: "",
      phone: "",
      company: "",
      validations: {
        name: true,
        emailAddress: true,
        subject: true,
        message: true,
        company: true,
        phone: true
      },
      success: null,
      loading: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);  }

  render() {
    return (
      <div className="contact-form hbox" id="ContactForm">
        <div className="column col-12">
          <h1 className="t-50 uppercase bold">{text.contact.title}</h1>
        </div>
        <div className="column col-4">
          <div className={this.state.validations.name ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.name}
            >
              {text.contact.form.name} *
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.name}
              name="name"
              onChange={this.handleChange}
              value={this.state.name}
              type="text"
            />
          </div>
          <div className={this.state.validations.company ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.company}
            >
              {text.contact.form.company}
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.company}
              name="company"
              value={this.state.company}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <div className={this.state.validations.emailAddress ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.emailAddress}
            >
              {text.contact.form.emailAddress} *
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.emailAddress}
              name="emailAddress"
              onChange={this.handleChange}
              value={this.state.emailAddress}
              type="emailAddress"
            />
          </div>
          <div className={this.state.validations.phone ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.phone}
            >
              {text.contact.form.phone}
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.phone}
              name="phone"
              value={this.state.phone}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <div className={this.state.validations.subject ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.subject}
            >
              {text.contact.form.subject}
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.subject}
              name="subject"
              value={this.state.subject}
              onChange={this.handleChange}
              type="text"
            />
          </div>

        </div>
        <div className="column col-8">
          <div className={this.state.validations.message ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.message}
            >
              {text.contact.form.message} *
            </label>
            <textarea
              className="full-width p-12 borderless mt-0 mb-0 h-438"
              id={text.contact.form.message}
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="column col-12">
          <div className="mt-20 pt-12 float-left t-13 lh-115 bold">
            * verplichte velden
          </div>
          <button
            className="button float-right"
            onClick={this.handleSubmit}
          >
            {text.contact.form.submit}
          </button>
        </div>
        <div className={"col-12 column t-20 bold align-center mt-30 h-64 relative"}>
          <div className={"center-vertically-and-horizontally " + (this.state.loading ? "opacity-100" : "opacity-0")}>
            <BarLoader sizeUnit={"px"} size={100} color={'white'} loading={this.state.loading} />
          </div>
          <span className={"transition-opacity "+(this.state.success ? "opacity-100" : "opacity-0")}>{text.contact.form.thanks}</span>
        </div>
      </div>
    )
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  handleSubmit() {
    let hasErrors = false;
    const validations = {
      name: true,
      company: true,
      emailAddress: true,
      subject: true,
      message: true,
      phone: true
    }
    if (this.state.name === "") {
      validations.name = false
      hasErrors = true;
    }
    if (this.state.emailAddress === "") {
      validations.emailAddress = false
      hasErrors = true;
    }
    if (!/\S+@\S+\.\S+/.test(this.state.emailAddress)) {
      validations.emailAddress = false
      hasErrors = true;
    }
    if (this.state.message === "") {
      validations.message = false
      hasErrors = true;
    }

    if (hasErrors) {
      this.setState(prevState => {
        return {
          ...prevState,
          validations: {
            name: validations.name,
            company: validations.company,
            emailAddress: validations.emailAddress,
            subject: validations.subject,
            message: validations.message,
            phone: validations.phone
          },
        }
      })
    } else {

      this.setState(prevState => {
        return {
          ...prevState,
          loading: true
        };
      });

      const bodyFormData = new FormData();
      bodyFormData.set('name', this.state.name)
      bodyFormData.set('company', this.state.company)
      bodyFormData.set('emailAddress', this.state.emailAddress)
      bodyFormData.set('subject', this.state.subject)
      bodyFormData.set('message', this.state.message)
      bodyFormData.set('phone', this.state.phone)

      axios({
        method: 'post',
        url: contact,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      }).then(() => {
        this.setState(() => {
          return {
            name: "",
            company: "",
            emailAddress: "",
            subject: "",
            message: "",
            phone: "",
            success: true,
            loading: false
          }
        })
        console.log(this.state)
      }).catch(err => {
        console.log(err)
      });
    }
  }
}

export default Form
